var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',[(!_vm.isSubmitted)?_c('v-card',{staticClass:"register-page",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"justify-center text-subtitle-1 font-weight-6 pb-3"},[_vm._v(" Registration ")]),_c('v-card-text',{staticClass:"text-center pb-3"},[_c('v-row',[_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"eg. unicorn@eatmol.com","label":"Email","color":"primary","autocomplete":"email","dense":"","outlined":"","hide-details":errors.length === 0,"error-messages":errors,"success":valid},model:{value:(_vm.registerForm.email),callback:function ($$v) {_vm.$set(_vm.registerForm, "email", $$v)},expression:"registerForm.email"}})]}}],null,false,1786341903)})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Contact No","rules":{ required: true, regex: /^\d{10,13}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"0123366899","label":"Contact No","color":"primary","autocomplete":"contact-no","dense":"","outlined":"","hide-details":errors.length === 0,"error-messages":errors,"success":valid},model:{value:(_vm.registerForm.contactNo),callback:function ($$v) {_vm.$set(_vm.registerForm, "contactNo", $$v)},expression:"registerForm.contactNo"}})]}}],null,false,3307307067)})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":{ required: true, min: 3, max: 30 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"eg. Gordon","label":"First Name","color":"primary","autocomplete":"first-name","dense":"","outlined":"","hide-details":errors.length === 0,"error-messages":errors,"success":valid},model:{value:(_vm.registerForm.firstName),callback:function ($$v) {_vm.$set(_vm.registerForm, "firstName", $$v)},expression:"registerForm.firstName"}})]}}],null,false,3610952351)})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":{ required: true, min: 3, max: 30 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"eg. Ramsay","label":"Last Name","color":"primary","autocomplete":"last-name","dense":"","outlined":"","hide-details":errors.length === 0,"error-messages":errors,"success":valid},model:{value:(_vm.registerForm.lastName),callback:function ($$v) {_vm.$set(_vm.registerForm, "lastName", $$v)},expression:"registerForm.lastName"}})]}}],null,false,446441477)})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"password","name":"Password","rules":{ required: true, min: 8 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"Strong password secure your account","label":"Password","color":"primary","autocomplete":"off","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"error-messages":errors,"success":valid,"dense":"","outlined":"","hide-details":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.registerForm.password),callback:function ($$v) {_vm.$set(_vm.registerForm, "password", $$v)},expression:"registerForm.password"}}),(errors.length > 0)?_c('ul',{staticClass:"text-caption text-left error--text mt-2"},[_c('li',[_vm._v("Min 8 characters")])]):_vm._e()]}}],null,false,4289908964)})],1),_c('v-col',{staticClass:"px-5 pb-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Confirm","rules":{ required: true, confirmed: 'password' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{staticClass:"register-form p-0 m-0",attrs:{"placeholder":"Confirm your password","label":"Confirm","color":"primary","autocomplete":"off","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"hide-details":errors.length === 0,"error-messages":errors,"success":valid,"dense":"","outlined":""},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.registerForm.confirmation),callback:function ($$v) {_vm.$set(_vm.registerForm, "confirmation", $$v)},expression:"registerForm.confirmation"}})]}}],null,false,3053183362)})],1),_c('v-col',{staticClass:"mt-1 pr-2 pl-5",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-caption",attrs:{"disabled":_vm.isLoading,"color":"grey lighten-3","block":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")])],1),_c('v-col',{staticClass:"mt-1 pl-2 pr-5",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-caption font-weight-6",attrs:{"disabled":_vm.isLoading,"color":"primary","block":""},on:{"click":function($event){return _vm.register()}}},[_vm._v("Register")])],1)],1)],1)],1):_c('v-card',[_c('v-card-title',{staticClass:"text-h5"}),_c('v-card-text',{staticClass:"px-5 text-body-2 text-justify font-weight-5"},[_vm._v(" Welcome "+_vm._s(_vm.registerForm.firstName + ' ' + _vm.registerForm.lastName)+", you may login to your new signup account now. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-body-2 text-capitalize font-weight-6 mr-1 mb-1",attrs:{"color":"primary","small":"","text":"","outlined":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Okay ! ")])],1)],1)],1),(_vm.error)?_c('v-alert',{staticClass:"alert-global text-body-2 white--text p-2",attrs:{"type":"error","transition":"fade-transition","dense":""}},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }